<template>
    <div id="container-footer">
        <a href="/privacypolicy" class="pp_footer">Politique de confidentialité</a>
        <ul class="list-footer">
            <div v-if="popcontact == true" id="other-c" class="pop-contact">
                <h5>Vous pouvez nous contacter par mail ou téléphone : </h5>
                <p class="nomargebottom">Secteur Rives de Loire : <a class="violet" href="mailto:collectifcouleursdelavieloire@gmail.com" title="Secteur Rive de Loire">collectifcouleursdelavieloire@gmail.com</a></p><p> ou au <a class="violet" href="tel:0695785284" title="Téléphone Rive de Loire">06.95.78.52.84</a></p>
                <p class="nomargebottom">Secteur Coeur du Vignoble : <a class="vert" href="mailto:collectiflescouleursdelavie@gmail.com" title="Secteur Coeur du Vignoble">collectiflescouleursdelavie@gmail.com</a></p><p> ou au <a class="vert" href="tel:0645463964" title="Téléphone Coeur du Vignoble">06.45.46.39.64</a></p>
                <button @click="pop" class="btn btn-danger btn-sm" type="button">Fermer</button>
            </div>
            <li> <a @click="pop" class="link-footer" href="#other-c" title="Afficher les coordonnées de contact"><img src="../assets/svg/envelope-regular.svg" width="15px" height="15px" alt="email"/> E-mail</a> </li>
            <li> <a @click="pop" class="link-footer" href="#other-c" title="Afficher les coordonnées de contact"><img src="../assets/svg/phone-alt-solid.svg" width="15px" height="15px" alt="telephone"/> Tél</a> </li>
            <li> <a class="link-footer" href="/">Les couleurs de la vie - 2022</a> </li>
        </ul>
        <a class="link-conception" title="Créateur du site (lien externe)" target="_blank" rel="noreferrer" href="https://davidbodineau.fr/">David Bodineau <img src="../assets/svg/external-link-alt-solid.svg" width="10px" height="10px" alt="external link"/></a>
    </div>
</template>

<script>
export default {
    name: 'thisfooter',
    data() {
        return {
            popcontact: false
        }
    },
    methods: {
        pop() {
            if (this.popcontact == true) {
                this.popcontact = false;
            }
            else if (this.popcontact == false) {
                this.popcontact = true;
            }
        }
    }
}
</script>

<style>
/* Pop contact */
.pop-contact {
    z-index: 100;
    position: absolute;
    top: 400px;
    left: 0;
    right: 0;
    margin: auto;
    background-color: rgba(56, 56, 56, 0.9);
    border: 2px solid black;
    color: white;
    border-radius: 10px;
    padding: 5px;
    width: 100%;
    max-width: 400px;
}
/* End pop contact / Start Style */
#container-footer {
    text-align: center;
    border-top: 1px solid rgb(190, 190, 190);
    padding: 10px 10px 10px 10px;
    margin-top: 5px;
}

.violet {
    color: violet;
}

.violet:hover, .vert:hover {
    color: white;
}

.vert {
    color: rgb(43, 170, 43);
}

.nomargebottom {
    margin-bottom: 0px;
}

ul {
    list-style: none;
}

.list-footer {
    justify-content: center;
}

li {
    color: black;
    margin: 0px 10px 0px 10px;
}

.link-conception {
    font-size: 0.7em;
    color: black;
    padding: 3px 8px 3px 8px;
}

.link-conception:hover {
    text-decoration: none;
    color: rgb(0, 140, 255);
    transition: .5s;
}

.link-footer {
    color: rgb(56, 75, 129);
}

.link-footer:hover {
    text-decoration: none;
}

.pp_footer {
    color: rgb(48, 26, 26);
}

.pp_footer:hover {
    text-decoration: none;
    color: crimson;
}

@media screen and (max-width: 450px) {

    .list-footer {
        flex-direction: column;
    }

    .list-footer li {
        margin-top: 5px;
    }
}
</style>