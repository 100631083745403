var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"container-footer"}},[_c('a',{staticClass:"pp_footer",attrs:{"href":"/privacypolicy"}},[_vm._v("Politique de confidentialité")]),_c('ul',{staticClass:"list-footer"},[(_vm.popcontact == true)?_c('div',{staticClass:"pop-contact",attrs:{"id":"other-c"}},[_c('h5',[_vm._v("Vous pouvez nous contacter par mail ou téléphone : ")]),_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_c('button',{staticClass:"btn btn-danger btn-sm",attrs:{"type":"button"},on:{"click":_vm.pop}},[_vm._v("Fermer")])]):_vm._e(),_c('li',[_c('a',{staticClass:"link-footer",attrs:{"href":"#other-c","title":"Afficher les coordonnées de contact"},on:{"click":_vm.pop}},[_c('img',{attrs:{"src":require("../assets/svg/envelope-regular.svg"),"width":"15px","height":"15px","alt":"email"}}),_vm._v(" E-mail")])]),_c('li',[_c('a',{staticClass:"link-footer",attrs:{"href":"#other-c","title":"Afficher les coordonnées de contact"},on:{"click":_vm.pop}},[_c('img',{attrs:{"src":require("../assets/svg/phone-alt-solid.svg"),"width":"15px","height":"15px","alt":"telephone"}}),_vm._v(" Tél")])]),_vm._m(4)]),_vm._m(5)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"nomargebottom"},[_vm._v("Secteur Rives de Loire : "),_c('a',{staticClass:"violet",attrs:{"href":"mailto:collectifcouleursdelavieloire@gmail.com","title":"Secteur Rive de Loire"}},[_vm._v("collectifcouleursdelavieloire@gmail.com")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v(" ou au "),_c('a',{staticClass:"violet",attrs:{"href":"tel:0695785284","title":"Téléphone Rive de Loire"}},[_vm._v("06.95.78.52.84")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"nomargebottom"},[_vm._v("Secteur Coeur du Vignoble : "),_c('a',{staticClass:"vert",attrs:{"href":"mailto:collectiflescouleursdelavie@gmail.com","title":"Secteur Coeur du Vignoble"}},[_vm._v("collectiflescouleursdelavie@gmail.com")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v(" ou au "),_c('a',{staticClass:"vert",attrs:{"href":"tel:0645463964","title":"Téléphone Coeur du Vignoble"}},[_vm._v("06.45.46.39.64")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{staticClass:"link-footer",attrs:{"href":"/"}},[_vm._v("Les couleurs de la vie - 2022")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"link-conception",attrs:{"title":"Créateur du site (lien externe)","target":"_blank","rel":"noreferrer","href":"https://davidbodineau.fr/"}},[_vm._v("David Bodineau "),_c('img',{attrs:{"src":require("../assets/svg/external-link-alt-solid.svg"),"width":"10px","height":"10px","alt":"external link"}})])
}]

export { render, staticRenderFns }