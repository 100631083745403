<template>
    <div id="privacy-container">
        <h1>Données personnelles <i class="fas fa-user-shield"></i></h1>
        <div>
            <h2 class="red">Que fait-on de mes données personnelles ?</h2>
            <p class="pp_text">
            Les informations recueillies dans le formulaire de contact sont enregistrées dans un fichier informatisé par l'hébergeur du site internet.<br>
            Objet du traitement des données : Le traitement des données personnelles dans le cadre de l’exécution d’un contrat ou de la préparation d’un devis.
            Les données marquées par un astérisque dans le formulaire de contact doivent obligatoirement être fournies.<br>
            Les données collectées seront communiquées aux seuls destinataires suivants : Le collectif "Les couleurs de la vie".<br>
            <strong>Elles sont conservées pendant 90 jours pour le formulaire de contact et sans limite de durée pour 
                les témoignages.</strong><br>
            Vous pouvez accéder aux données vous concernant, les rectifier, demander leur effacement ou exercer votre droit à la limitation du traitement de vos données.<br>
            Vous pouvez retirer à tout moment votre consentement au traitement de vos données ; Vous pouvez également exercer votre droit à la portabilité de vos données.
            Consultez le site cnil.fr pour plus d’informations sur vos droits.<br>
            Pour exercer ces droits ou pour toute question sur le traitement de vos données dans ce dispositif, vous pouvez contacter 
            notre délégué à la protection des données :<strong> contactez-nous</strong>.
            Si vous estimez, après nous avoir contactés, que vos droits « Informatique et Libertés » ne sont pas respectés, vous pouvez adresser une réclamation à la CNIL.<br>
            </p>
        </div>
        <div class="bloc-infos-pol-conf">
            <a href="/" class="btn btn-success btn-pol-conf"><i class="fas fa-home"></i> Accueil</a>
            <a href="/contact" class="btn btn-info btn-pol-conf">Nous contacter</a>
        </div>
    </div>
</template>

<script>
export default {
    name: 'privacypolicy'
}
</script>

<style>
/* A ENLEVER DES COMPLETION */
.blue {color: blue;}

/* CSS */
#privacy-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.pp_text {
    border: 3px solid rgb(4, 87, 4);
    max-width: 1100px;
    margin-left: 5px;
    margin-right: 5px;
    padding: 10px;
}

.bloc-infos-pol-conf {
    display: flex;
    margin: 10px;
}

.btn-pol-conf {
    margin: 5px;
}
</style>