<template>
    <div id="qsn-container" class="background-qsn">
        <div id="info-titre">
            <h1 class="bk-qsn">Qui sommes nous ?</h1>  
        </div>
        <article id="text-qsn">
            <div id="qsn-title-bloc">
                <div id="title-h2-qsn">
                    <h2>Un collectif tout en couleur !</h2>
                </div>
            </div>
            <h3 class="text-align border-bottom"><strong>C</strong>réation du collectif...</h3><br>
            <p class="text-qsn anim01">Le collectif a été créé par Justine et Fabienne, avec le souhait d'accompagner les personnes selon leurs visions du prendre soin : 
            </p>
            <ul class="text-qsn anim01 list-price">
                <li>- respect de leurs rythmes, de leurs besoins ainsi que ceux des intervenantes,</li>
                <li>- respect de soi pour le respect de l'autre.</li>
            </ul>
            <figure class="flex-column">
                <img class="img-qsn-1" src="../../assets/cultiver-bienveillance-emotions1.jpg" title="Cultiver la bienveillance" alt="Photo de 2 personnes avec une fleur dans la main">
                <figcaption>
                    <i class="subtitle-qsn">Cultiver la bienveillance - © iStock / Hakase_</i>
                </figcaption>
            </figure>
            <p class="text-qsn anim01">
                Elles ont rencontré le collectif chouchout'âge qui se situe à Angers.
                Elles ont suivi une formation par ce collectif pour les guider dans la mise en oeuvre. Elles ont ensuite été rejointes par Carole, Fanny, Patricia et Catherine puis
                Manon, Hélène et Sophie.
            </p>
            <h4 class="border-bottom"><strong>L</strong>es projets</h4><br>
            <p class="text-qsn anim02">Suite au déménagement de Fabienne, une nouvelle antenne des couleurs de la vie est née à Orée d'Anjou. Les couleurs de la vie
                comptent donc maintenant deux secteurs : "Coeur du Vignoble" et "Rives de Loire". <br>
            </p>
            <p class="text-qsn">
                Selon l'évolution des besoins, ces deux secteurs peuvent accueillir de nouvelles auxiliaires de vie motivées pour travailler conformément à notre charte.
                Celle-ci définit notre fonctionnement et nos valeurs propres.
                Pour créer un autre collectif, vous pouvez contacter <a href="http://www.chouchoutage.fr/" target="_blank" rel="noreferrer">Chouchout'âge</a> qui organise régulièrement des rencontres et transmet son expérience.
            </p>
            <h4 class="border-bottom"><strong>À</strong> propos de nous...</h4><br>
            <p class="text-qsn anim01">Le collectif réunit 11 auxiliaires de vie. Chacune est autonome, en CESU et en même temps, travaille en équipe, dans un fonctionnement non hiérarchique. Ainsi, le secrétariat est assuré à tour de rôle par chacune des professionnelles.<br>
            </p>
            <div>
                <p style="padding-left: 10px;">
                Une charte nous réunit, elle définit nos valeurs, notre manière de travailler pour permettre une cohérence dans nos actions.<br>
                </p>
                <p style="padding-left: 10px;">
                Chaque professionnelle est la garante du respect de cette charte.<br>
                </p>
                <p style="padding-left: 10px;">
                Nous limitons le nombre d'intervenantes à 2 ou 3 pour assurer une continuïté dans l'accompagnement.<br>
                </p>
            </div>
            <p class="text-qsn">
                Lorsque vous nous contactez, la secrétaire écoute votre demande et la transmet à l'équipe. Une rencontre est organisée avec vous et les personnes disponibles pour vous accompagner.<br>
            </p>
            <figure class="flex-column">
                <img class="img-qsn-0" src="../../assets/collectif.webp" title="Les Couleurs de la vie" alt="Membres : Les Couleurs de la vie">
                <figcaption class="img-qsn-0-legend">
                    <a title="Accèdez à l'article" target="_blank" rel="noreferrer" href="https://www.ouest-france.fr/pays-de-la-loire/chateau-thebaud-44690/chateau-thebaud-aides-a-domicile-elles-sont-les-couleurs-de-la-vie-7020552"><i>Lire l'article de presse OUEST FRANCE</i></a>
                    <i>Debout : Fabienne Galpin, Patricia Perennes et Carole Charié ; assises : Justine Josse Gobin, Fanny Schumacher et Catherine Hégron</i>
                </figcaption>
            </figure>
            <p class="text-qsn">Les couleurs de la vie se compose de deux équipes, une antenne "Coeur du Vignoble", qui regroupe
                <strong>Justine, Catherine, Patricia, Manon, Fanny, Hélène, Carole et Sophie</strong>. Récemment, une nouvelle antenne a été créée "Rives de Loire", qui regroupe
                <strong>Nathalie, Fabienne et Sandrine</strong>. Les couleurs se réunissent une fois par mois pour échanger sur le collectif et de façon personnelle.
             </p>
        </article>
        <div>
            <span class="border-span"></span>
            <div class="bloc-all-square">
                <div class="bloc-to-square">
                    <a class="bloc-square bloc1" title="Retourner à l'accueil" href="/">ACCUEIL</a>
                    <a class="bloc-square bloc2" title="Nos services" href="/services">NOS SERVICES</a>
                </div>
                <div class="bloc-to-square">
                    <a class="bloc-square bloc3" title="Les témoignages" href="/temoignages">TEMOIGNAGES</a>
                    <a class="bloc-square bloc4" title="Tarifs et contrats" href="/tarifs">TARIFS ET CONTRATS</a>
                </div>
            </div>
            <div class="bloc-all-square-tel">
                <a class="bloc1-tel" title="Retourner à l'accueil" href="/">ACCUEIL</a>
                <a class="bloc2-tel" title="Nos services" href="/services">NOS SERVICES</a>
                <a class="bloc3-tel" title="Les témoignages" href="/temoignages">TEMOIGNAGES</a>
                <a class="bloc4-tel" title="Tarifs et contrats" href="/tarifs">TARIFS ET CONTRATS</a>
            </div>
            <span class="border-span2"></span>
        </div>
    </div>
</template>

<script>
export default {
    name: 'qsn'
}
</script>

<style>
#qsn-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    animation: 1s textY;
}

#qsn-title-bloc {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    width: 100%;
}

#info-titre {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: center no-repeat url("../../assets/1920x1280-personne-agee-01.jpg");
    background-size: cover;
    background-attachment: fixed;
    text-align: center;
    width: 100%;
    height: 550px;
    margin-bottom: 30px;  
}

#info-titre h1 {
    background-color: rgba(0, 0, 0, 0.3);
    color: white;
}

.bk-qsn {
    background-color: transparent;
    border-bottom: 2px solid rgba(0, 0, 0, 0.4);
}

#title-h2-qsn h2 {
    font-size: 2.5em;
    font-weight: normal;
    background-color: rgba(255, 236, 194, 0.5);
    color: rgb(0, 0, 0);
    box-shadow: 0px 0px 25px 1px rgba(128, 128, 128, 0.5);
    text-align: center;
    border-radius: 8px;
    padding-top: 20px;
    padding-bottom: 30px;
    margin-bottom: 30px;
    width: 100%;
}

.img-qsn-0, .img-qsn-1, .img-qsn-2{
    width: 430px;
    margin-top: 10px;
    border-radius: 5px;
    box-shadow: 0px 0px 40px 1px rgba(0, 0, 0, 0.4);
}

.img-qsn-0 {
    animation: 1s imgwidth;
}

.img-qsn-0-legend {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-top: 15px;
    padding-left: 8px;
    padding-right: 8px;
}

#text-qsn {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 1000px;
    top: -80px;
    background-color: white;
    margin-bottom: 30px;
    font-size: 1.1em;
    border-radius: 10px;
}

.text-qsn {
    text-indent: 20px;
    padding-left: 8px;
    padding-right: 8px;
}

.img-qsn-1 {
    width: 400px;
    margin-top: 10px;
    border-radius: 5px;
    box-shadow: 0px 0px 40px 1px rgba(0, 0, 0, 0.4);
}

.subtitle-qsn {
    margin-bottom: 50px;
}

/* Animations */

.anim01 {
    animation: 1.5s textX;
}

.anim02 {
    animation: 1.6s textX2;
}

@keyframes imgwidth {
    from {
        width: 380px;
    }
    to {
        width: 430px;
    }
}

@keyframes imgwidthtel {
    from {
        width: 230px;
    }
    to {
        width: 270px;
    }
}

@media screen and (max-width: 480px) {
    #info-titre {
        background-attachment: scroll;
    }
}

@media screen and (max-width: 450px) {

    .bk-qsn {
        font-size: 2em;
    }

    #title-h2-qsn h2 {
        font-size: 1.7em;
    }

    .img-qsn-1, .img-qsn-0, .img-qsn-2 {
        width: 270px;
    }

    .img-qsn-0 {
        animation: 1s imgwidthtel;
    }

    #text-qsn a {
        max-width: 70%;
        text-align: center;
    }
}

@media screen and (max-width: 380px) {

    #title-h2-qsn {
        display: flex;
    }

    #title-h2-qsn h2{
        font-weight: normal;
        font-size: 1.3em;
    }
}
</style>