<template>
    <div>
        <nav id="nav-1" class="navbar navbar-dark navbar-style">
            <ul>
                <li class="list-navbar anim-nav"> <a class="link-navbar" href="/" title="Page d'accueil">Accueil </a> </li>
                <li class="list-navbar anim-nav"> <a class="link-navbar" href="/services" title="Nos prestations">Nos services </a> </li>
                <li class="list-navbar anim-nav"> <a class="link-navbar" href="/quisommesnous" title="Qui sommes nous ?">Qui sommes nous ? </a> </li>
                <li class="list-navbar anim-nav"> <a class="link-navbar" href="/temoignages" title="Les témoignages">Témoignages </a> </li>
                <li class="list-navbar anim-nav"> <a class="link-navbar" href="/tarifs" title="Nos tarifs et contrats">Tarifs et contrats</a> </li>
                <li class="list-navbar anim-nav"> <a class="link-navbar" href="/contact" title="Contactez nous !">Nous contacter </a> </li>
            </ul>
            <div id="nav-contact">
                <a v-if="isConnect == null" class="link-navbar2" href="/login" title="Aller à l'espace membre">Connexion</a>
                <a v-if="isConnect != null" class="link-navbar2" href="/rHImFxsStqIxEQSYc1N9iipZRuXMmZE2KFGOISKM" title="Espace perso">Espace perso</a>
            </div>
        </nav>
        <nav id="nav-2" class="anim-nav">
            <div>
                <button class="btn btn-dark nowrap" @click="displayMenu"><img src="../assets/svg/bars-solid.svg" width="20px" height="20px" alt="menu"> MENU <img src="../assets/svg/angle-down-solid.svg" width="16px" height="16px" alt="menu" v-if="menu == false"/><img src="../assets/svg/angle-up-solid.svg" width="16px" height="16px" alt="menu" v-if="menu == true"/></button>
                <span v-if="menu == true" class="menu-tel">
                    <ul class="menu-tel-list">
                        <div>
                            <li><a class="lien-a-menu-tel" href="/" title="Page d'accueil">Accueil</a></li>
                            <li><a class="lien-a-menu-tel" href="/services" title="Nos prestations">Nos services</a></li>
                            <li><a class="lien-a-menu-tel" href="/quisommesnous" title="Qui sommes nous ?">Qui sommes nous ?</a></li>
                            <li><a class="lien-a-menu-tel" href="/temoignages" title="Les témoignages">Témoignages</a></li>
                            <li><a class="lien-a-menu-tel" href="/tarifs" title="Nos tarifs et contrats">Tarifs et contrats</a></li>
                            <li><a class="lien-a-menu-tel" href="/contact" title="Nous contacter">Nous contacter</a></li>
                            <li><a href="#" title="Fermer le menu" @click="displayMenu" class="close-menu lien-a-menu-tel">Fermer le menu</a></li>
                        </div>
                        <div class="footer-menu">
                            <p>Les couleurs de la vie - 2022</p>
                            <a class="link-conception" title="Créateur du site (lien externe)" target="_blank" href="https://davidbodineau.fr/">Créé par David Bodineau <img src="../assets/svg/external-link-alt-solid.svg" width="10px" height="10px" alt="external link"/></a>
                        </div>
                    </ul>
                </span>
            </div>
            <div id="nav-contact">
                
                <a @click="pop" class="link-navbar2" href="#nav-2" title="Afficher les coordonnées de contact"><img src="../assets/svg/envelope-regular.svg" width="15px" height="15px" alt="email"/> E-mail</a>
                <a @click="pop" class="link-navbar2" href="#nav-2" title="Afficher les coordonnées de contact"><img src="../assets/svg/phone-alt-solid.svg" width="15px" height="15px" alt="telephone"/> Tél</a>
                
                <div>
                    <a v-if="isConnect == null" class="link-navbar2" href="/login" title="Aller à l'espace membre">Connexion</a>
                    <a v-if="isConnect != null" class="link-navbar2" href="/rHImFxsStqIxEQSYc1N9iipZRuXMmZE2KFGOISKM" title="Espace perso">Espace perso</a>
                </div>
            </div>
        </nav>
        <div v-if="popcontact == true" id="other-c" class="pop-contact2">
            <h5>Vous pouvez nous contacter par mail ou téléphone : </h5>
            <p class="nomargebottom">Secteur Rives de Loire : </p><p class="nomargebottom"><a class="violet" href="mailto:collectifcouleursdelavieloire@gmail.com" title="Secteur Rive de Loire">collectifcouleursdelavieloire@gmail.com</a></p><p> ou au <a class="violet" href="tel:0695785284" title="Téléphone Rive de Loire">06.95.78.52.84</a></p>
            <p class="nomargebottom">Secteur Coeur du Vignoble : <a class="vert" href="mailto:collectiflescouleursdelavie@gmail.com" title="Secteur Coeur du Vignoble">collectiflescouleursdelavie@gmail.com</a></p><p> ou au <a class="vert" href="tel:0645463964" title="Téléphone Coeur du Vignoble">06.45.46.39.64</a></p>
            <button @click="pop" class="btn btn-danger btn-sm" type="button">Fermer</button>
        </div>
    </div>
</template>

<script>
export default {
    name: 'navigation',
    data() {
        return {
            menu: false,
            isConnect: localStorage.token,
            popcontact: false
        }
    },
    methods : {
        displayMenu() {
            if(this.menu == true) {
                this.menu = false;
            } else if (this.menu == false) {
                this.menu = true;
            }
        },
        pop() {
            if (this.popcontact == true) {
                this.popcontact = false;
            }
            else if (this.popcontact == false) {
                this.popcontact = true;
            }
        }
    }
}
</script>

<style>
#nav-1 {
    background-color: rgb(255, 255, 255);
    box-shadow: 0px 0px 25px 1px rgb(233, 233, 233);
    border-bottom: 1px solid rgb(194, 194, 194);
    padding-top: 20px;
    padding-bottom: 20px;
}

#nav-2 {
    display: none;
    align-items: center;
    padding-top: 15px;
    padding-left: 15px;
    padding-bottom: 10px;
    box-shadow: 0px 0px 25px 1px rgb(233, 233, 233);
    border-bottom: 1px solid rgb(194, 194, 194);
}

ul {
    margin: 0;
    padding: 5px;
}

ul, .title-navbar {
    display: flex;
}

.list-navbar {
    list-style: none;
    margin: 0px;
}

.link-navbar {
    color: rgb(0, 0, 0);
    padding: 12px 13px 12px 13px;
    font-size: 1.1em;
    border-radius: 5px;
}

.link-navbar:hover {
    color: rgb(0, 0, 0);
    background-color: #556c7f;
    color: white;
    transition: .2s;
}

.link-navbar:focus {
    color: rgb(0, 0, 0);
    background-color: #556c7f;
    color: white;
    transition: .4s;
}

.link-navbar:hover {
    text-decoration: none;
    transition: .5s;
}

#nav-contact {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

#nav-contact a {
    padding: 12px 5px 12px 5px;
    margin: 5px;
    text-decoration: none;
    color: black;
}

#nav-contact a:hover {
    background-color: #556c7f;
    color: white;
    border-radius: 5px;
    transition: .5s;
}

/* Menu phone */
.menu-tel {
    min-width: 100px;
    min-height: 100px;
}

.menu-tel-list {
    z-index: 6;
    position: absolute;
    display: flex;
    align-items: center;
    flex-direction: column;
    background-color: #ffffffe3;
    min-height: 100%;
    min-width: 100%;
    left: 0px;
    animation: 1s menutel;
    padding-top: 20px;
}

.menu-tel-list div {
    display: flex;
    flex-direction: column;
}

.menu-tel-list li {
    text-decoration: none;
    text-align: left;
    margin: 0;
    margin-bottom: 20px;
    width: 100%;
}

.menu-tel-list div li a {
    text-decoration: none;
    font-size: 1.2em;
}

.lien-a-menu-tel {
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 5px;
}

.footer-menu {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-top: 2px solid rgb(33, 33, 68);
    width: 80%;
}

.footer-menu p {
    padding-top: 50px;
}

.menu-tel-list li a {
    color: rgb(0, 0, 0);
    padding: 9px;
}

.menu-tel-list li a:hover {
    background-color: rgb(208, 227, 255);
    border-radius: 5px;
}

.close-menu {
    font-weight: bold;
    background-color: rgba(255, 0, 47, 0.3);
    padding: 5px;
    border-radius: 5px;
}

.nowrap {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
}

.nowrap i {
    margin-left: 5px;
    margin-right: 5px;
}

/* Pop contact */
.pop-contact2 {
    position: absolute;
    z-index: 10;
    left: 0;
    right: 0;
    margin: auto;
    background-color: rgba(56, 56, 56, 0.9);
    border: 2px solid black;
    color: white;
    border-radius: 10px;
    padding: 5px;
    width: 100%;
    max-width: 400px;
    text-align: center;
}

#pop-nav:hover {
    background-color: transparent!important;
}
/* End pop contact / Start Style */

/* Animations */
.anim-nav {
    animation: 1s navigation;
}

@media screen and (max-width: 940px) {
    #nav-1 {
        display: none;
    }

    #nav-2 {
        display: flex;
    }

    .navbar-toggler {
        background-color: black;
    }
}

@media screen and (max-width: 890px) {
    #nav-contact {
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }
}

/* KeyFrames */
@keyframes navigation {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes menutel {
    from {
        opacity: 0;
        transform: translateY(-100px);
    }
    to {
        opacity: 1;
        transform: translateY(0px);
    }
}
</style>