<template>
    <div id="home-membre" v-if="sessionValid != null">
        <h1>Tableau de bord</h1>
        <div class="option-memco">
            <p class="text-align">Bienvenue {{ userData.prenom }} {{userData.nom }}</p>
            <p class="green" v-if="deletePostSucc.length > 5">{{ deletePostSucc }}</p>
            <button @click="deco" class="btn btn-dark btn-sm width" title="Déconnexion">Se déconnecter</button>
            <button @click="modPost" class="btn btn-dark btn-sm width" title="Espace modération">Modérer les messages</button>
            <button @click="modifyPassword" v-if="displayModify == false" class="btn btn-warning btn-sm width" title="Modifier le mot de passe">Modifier le mot de passe</button>
            <button @click="modifyPassword" v-if="displayModify == true" class="btn btn-success btn-sm width" title="Retour">Annuler la modification du mot de passe</button>
            <form id="form-modify" v-if="displayModify == true">
                <div v-if="pass.oldPass.length > 4 && pass.newPass.length > 4">
                    <p class="error">{{ regex.errorsOld[0] }}</p>
                </div>
                <p v-if="oldPassForm.length != null" class="red">{{ oldPassForm }}</p>
                <div v-if="pass.oldPass.length > 4 && pass.newPass.length > 4">
                    <p class="error">{{ regex.errorsNew[0] }}</p>
                </div>
                <div>
                    <label class="label-form">Ancien mot de passe</label>
                    <input @keyup="checkForm" v-model="pass.oldPass" class="input-form input-login" type="password" minlength="8" maxlength="25" placeholder="••••••••••" autocomplete="current-password" required>
                </div>
                <div>
                    <label class="label-form">Nouveau mot de passe</label>
                    <input @keyup="checkFormNew" v-model="pass.newPass" class="input-form input-login" type="password" minlength="8" maxlength="25" placeholder="••••••••••" autocomplete="current-password" required>
                </div>
                <button @click="newPass" :disabled="regex.disabled === true && regex.disabledNew === true" class="btn btn-success btn-sm width" title="Modifier mon mot de passe" type="button">Modifier mon mot de passe</button>
            </form>
            <button @click="deleteAccount" v-if="displayDelete == false" class="btn btn-danger btn-sm width" title="Désactiver mon compte">Désactiver mon compte</button>
            <button @click="deleteAccount" v-if="displayDelete == true" class="btn btn-success btn-sm width" title="Retour">Annuler la désactivation de compte</button>
            <form id="form-delete" v-if="displayDelete == true">
                <div v-if="deleteA.password.length > 4">
                    <p class="error">{{ regexDelete.errorsDelete[0] }}</p>
                </div>
                <div>
                    <label class="label-form">Mot de passe</label>
                    <input @keyup="checkFormDelete" v-model="deleteA.password" class="input-form input-login" type="password" minlength="8" maxlength="25" placeholder="••••••••••" autocomplete="current-password" required>
                </div>
                <button @click="deleteUserAccount" :disabled="regexDelete.disabledDelete == true" title="Désactiver définitivement mon compte" class="btn btn-danger btn-sm width" type="button">Désactiver définitivement mon compte</button>
            </form>
        </div>
        <div id="expl-membre">
            <h2>Note explicative</h2>
            <p class="expl-memco">Bienvenue dans l'espace dédié aux membres. Vous pouvez supprimer les témoignages depuis cet espace. Il vous suffit de cliquer
                sur le bouton <strong>"Modérer les messages"</strong>, <strong>"supprimer le témoignage"</strong> puis <strong>"Valider la suppression"</strong>.
            </p>
        </div>
        <section id="section-posts-co" v-for="(post, listPosts) in getDataTesti.allPosts" v-bind:key="listPosts">
            <div id="posts-list">
                <button @click="postId = post.id, deleteOk()" class="btn btn-danger btn-sm btn-memco" title="Supprimer ce témoignage">Supprimer le témoignage</button>
                <div id="delete-post" v-if="displayVerif == true && postId == post.id">
                    <button @click="deletePost" class="btn btn-danger btn-sm" title="Supprimer ce témoignage">Valider la suppression</button>
                    <button @click="deleteOk" class="btn btn-success btn-sm" title="Annuler la suppression">Annuler</button>
                </div>
                <div class="posts-list-title">
                    <h5><strong> {{ post.firstname }} </strong></h5>
                    <h6><strong> {{ post.date }} (n°{{ post.id }})</strong></h6>
                </div>
                <div class="posts-list-text">
                    <p>{{ post.text }}</p>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import axios from "axios"

export default {
    name: 'sLjytt2O3T',
    data() {
        return {
            sessionValid: localStorage.token,
            verif: localStorage.DsPZBSOxyP6m1Tc,
            getDataTesti: {
                allPosts: []
            },
            pass: {
                oldPass: "",
                newPass: ""
            },
            deleteA: {
                password: ""
            },
            regex: {
                errorsOld: [],
                errorsNew: [],
                disabled: true,
                disabledNew: true
            },
            regexDelete: {
                errorsDelete: [],
                disabledDelete: true
            },
            userData: {
                prenom: localStorage.prenom,
                nom: localStorage.nom,
            },
            postId: "",
            displayDelete: false,
            displayModify: false,
            displayVerif: false,
            oldPassForm: "",
            msgErr: "",
            msgSucc: "",
            deletePostSucc: ""
        }
    },
    methods: {
        deco() {
            localStorage.clear();
            this.$router.push('/');
            location.reload();
        },
        deleteAccount() {
            if(this.displayDelete == true) {
                this.displayDelete = false;
            } else if (this.displayDelete == false) {
                this.displayDelete = true;
            }
        },
        modifyPassword() {
            if(this.displayModify == true) {
                this.displayModify = false;
            } else if (this.displayModify == false) {
                this.displayModify = true;
            }
        },
        deleteOk() {
            if(this.displayVerif == true) {
                this.displayVerif = false;
            } else if (this.displayVerif == false) {
                this.displayVerif = true;
            }
        },
        checkForm:function() {
            this.regex.errorsOld = [];
            if (this.regex.errorsOld.length == 0 && this.regex.errorsNew.length == 0) {
                this.regex.disabled = false;
                this.regex.disabledNew = false;
            }
            if (!this.pass.oldPass) {
                this.regex.errorsOld.push("Le mot de passe est nécessaire.");
                this.regex.disabled = true;
                this.regex.disabledNew = true;
            }
            if(!this.verificationPassword(this.pass.oldPass)) {
                this.regex.errorsOld.push("Ancien mot de passe : 8 caractères et 1 chiffre minimum demandés");
                this.regex.disabled = true;
                this.regex.disabledNew = true;
            }
        },
        verificationPassword:function(pass) {
            this.pass.oldPass = pass;
            //eslint-disable-next-line
            let rePass = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/;
            return rePass.test(pass);
        },
        // New password
        checkFormNew:function() {
            this.regex.errorsNew = [];
            if (this.regex.errorsNew.length == 0 && this.regex.errorsOld.length == 0) {
                this.regex.disabledNew = false;
                this.regex.disabled = false;
            }
            if (!this.pass.newPass) {
                this.regex.errorsNew.push("Le mot de passe est nécessaire.");
                this.regex.disabledNew = true;
                this.regex.disabled = true;
            }
            if(!this.verificationPasswordNew(this.pass.newPass)) {
                this.regex.errorsNew.push("Nouveau mot de passe : 8 caractères et 1 chiffre minimum demandés");
                this.regex.disabledNew = true;
                this.regex.disabled = true;
            }
        },
        verificationPasswordNew:function(passNew) {
            this.pass.newPass = passNew;
            //eslint-disable-next-line
            let rePassNew = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/;
            return rePassNew.test(passNew);
        },
        checkFormDelete:function() {
            this.regexDelete.errorsDelete = [];
            if (this.regexDelete.errorsDelete.length == 0) {
                this.regexDelete.disabledDelete = false;
            }
            if (!this.deleteA.password) {
                this.regexDelete.errorsDelete.push("Le mot de passe est nécessaire.");
                this.regexDelete.disabledDelete = true;
            }
            if(!this.verificationPasswordDelete(this.deleteA.password)) {
                this.regexDelete.errorsDelete.push("Nouveau mot de passe : 8 caractères et 1 chiffre minimum demandés");
                this.regexDelete.disabledDelete = true;
            }
        },
        verificationPasswordDelete:function(passDelete) {
            this.deleteA.password = passDelete;
            //eslint-disable-next-line
            let rePassDelete = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/;
            return rePassDelete.test(passDelete);
        },
        /* end */
        modPost(){
            axios.get("https://collectiflescouleursdelavie.fr:3000/getmessagemod", {headers: {'Content-Type': 'application/json', Authorization: 'Bearer ' + localStorage.token}})
            .then(response => {
                let dataTesti = response.data;
                this.getDataTesti.allPosts = dataTesti;
            })
            .catch(() => {
            });
        },
        newPass() {
            let newPass = JSON.stringify(this.pass);
            axios.put("https://collectiflescouleursdelavie.fr:3000/W9rcFh5QOGs5VxQ5aGyPjXS0JN45HBjO2z73vce9", newPass, {headers: {'Content-Type': 'application/json', Authorization: 'Bearer ' + localStorage.DsPZBSOxyP6m1Tc}})
            .then(() => {
                location.reload();
            })
            .catch(() => {
                this.oldPassForm = "Ancien mot de passe incorrect";
            })
        },
        deleteUserAccount() {
            let passDA = JSON.stringify(this.deleteA);
            axios.put("https://collectiflescouleursdelavie.fr:3000/5UUtkKmbNgHmgnFW0CPuAxe2rXvLkwgPY2ZU3tXRUb99bGnq1T", passDA, {headers: {'Content-Type': 'application/json', Authorization: 'Bearer ' + localStorage.DsPZBSOxyP6m1Tc}})
            .then(() => {
                localStorage.clear();
                this.$router.push('/');
                location.reload();
            })
            .catch(() => {
            })
        },
        deletePost() {
            let pId = this.postId;
            axios.delete("https://collectiflescouleursdelavie.fr:3000/jzgWx3MpAeD4EGdP0nIhxYCmCvsnq8nmzQqXZDLS", { data: { postId: pId }, headers: {'Content-Type': 'application/json', Authorization: 'Bearer ' + localStorage.DsPZBSOxyP6m1Tc}})
            .then(() => {
                this.modPost();
                this.deletePostSucc = "Message supprimé !";
            })
            .catch(() => {
                location.reload();
            })
        },
    },
    mounted() {
        axios.get("https://collectiflescouleursdelavie.fr:3000/AQercueT33BxwQqqaXbY2HW9R7XoxFSbjtG3Ul7S", {headers: {'Content-Type': 'application/json', Authorization: 'Bearer ' + localStorage.token}})
        .then(() => {
        })
        .catch(() => {
            localStorage.clear();
            this.$router.push('/');
        })
    }
}
</script>

<style>
#home-membre, #expl-membre {
    display: flex;
    flex-direction: column;
    align-items: center;
}

#home-membre h1 {
    border-bottom: 3px solid grey;
    padding-bottom: 0px;
    margin-bottom: 15px;
    text-align: center;
}

.option-memco {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 280px;
    box-shadow: 0px 0px 20px 1px rgba(0, 0, 0, 0.4);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    border-bottom: 3px solid rgb(255, 174, 0);
    border-top: 3px solid rgb(255, 174, 0);
    padding-top: 20px;
    padding-bottom: 15px;
    margin-top: 15px;
    margin-bottom: 20px;
}

.option-memco button {
    margin: 7px;
}

#section-posts-co {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 10px;
    padding: 10p;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 15px;
    box-shadow: 0px 0px 20px 1px rgba(233, 233, 233);
    animation: 2s formulaire;
    width: 70%;
    min-width: 270px;
    max-width: 900px;
}

.expl-memco {
    text-align: center;
}

#posts-list {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.btn-memco {
    margin-bottom: 10px;
    padding: 5px;
}

/* Modifier, supprimer le mot de passe */
#form-modify, #form-delete {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 10px;
    background-color: #e7e7e7;
}

#form-modify div, #form-delete div {
    display: flex;
    flex-direction: column;
}

.pass-look {
    display: flex;
    flex-direction: row;
}

.width {
    width: 250px;
}
/* Delete post */

#delete-post {
    display: flex;
    position: absolute;
    background-color: rgb(0, 0, 0, 0.85);
    box-shadow: 0px 0px 20px 1px rgba(0, 0, 0, 0.4);
    padding: 20px;
    border-radius: 5px;
}

#delete-post button {
    margin-left: 5px;
    margin-right: 5px;
}
</style>