<template>
    <div id="container">
        <div id="home-titre">
            <h1 class="home-h1">Les couleurs de la vie</h1>
            <h2 class="home-h2">Accompagnement des personnes à domicile dans leur vie sociale et quotidienne et relais aux aidants</h2>
        </div>
        <div class="container-homepresentation">
            <homepresentation/>
        </div>
    </div>
</template>

<script>

import homepresentation from './contenu/accueil_contenu.vue'

export default {
    name: 'home',
    components: {
        homepresentation
    },
}
</script>

<style>

#home-titre {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    background: center no-repeat url("../assets/flower.jpg");
    background-size: cover;
    background-attachment: fixed;
    height: 550px;
}

.home-h1, .home-h2 {
    background-color: rgba(255, 255, 255, 0.8);
}

.home-h1 {
    font-size: 4em;
    color: rgb(202, 162, 31);
}

.home-h2 {
    color: rgb(0, 0, 0);
    border-bottom: 2px solid rgba(0, 0, 0, 0.4);
}

@media screen and (max-width: 680px) {
    .home-h1 {
        font-size: 2.5em;
    }

    .home-h2 {
        font-size: 1.3em;
    }
}

@media screen and (max-width: 480px) {
    #home-titre {
        background-attachment: scroll;
    }
}
</style>