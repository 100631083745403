<template>
    <article id="home-presentation">
        <div id="bloc-title-presentation">
            <h3 class="style-title-presentation">Bienvenue sur le site internet du collectif Les couleurs de la vie</h3>
            <h4 class="subtitle">Le collectif</h4>
        </div>
        <div class="bloc-text-home-presentation">
            <p class="text-qsn">
                Nous sommes un collectif de salariées indépendantes et professionnelles, travaillant en réseau, dans un esprit de <strong>coopération</strong> 
                et de <strong>partage</strong> au <strong>service</strong> de la personne à domicile et de ses aidants.
            </p>
            <p class="text-qsn">
                Le <strong>bien-être</strong> de la personne est au coeur de notre démarche qui s'inscrit ainsi dans le <strong>partage</strong> et le
                <strong>respect</strong> de votre rythme, vos habitudes de vie, vos valeurs, votre demande, vos besoins et vos envies.
                Nous limitons le plus possible le nombre d'intervenantes.
            </p>
            <ul class="list-presentation">
                <li><strong> Une aide à la vie sociale </strong> </li>
                <li><strong>Un accompagnement dans les gestes de la vie quotidienne</strong></li>
            </ul>
            <p class="text-qsn">Nous intervenons principalement soit chez vous, chez vos proches ou à l'hôpital et maison de retraite.</p>
            <p class="text-qsn">Nous avons deux secteurs : Coeur du Vignoble et Rives de Loire. Voici les communes desservies pour chacuns d'entre eux :</p>
            <div class="bloc-communes">
                <div class="cdv-list">
                    <div class="cdv-title text-align"><strong>Coeur du Vignoble</strong></div>
                    <ul>
                        <li>St Sébastien sur loire</li>
                        <li>Vertou</li>
                        <li>Haute Goulaine</li>
                        <li>La Haye Fouassière</li>
                        <li>Monnieres</li>
                        <li>Le Pallet</li>
                        <li>La Chapelle Heulin</li>
                        <li>St Lumine de Clisson</li>
                        <li>Clisson</li>
                        <li>Château Thebaut</li>
                        <li>Gorges</li>
                    </ul>
                </div>
                <div class="rdl-list">
                    <div class="rdl-title text-align"><strong>Rives de Loire</strong></div>
                    <ul>
                        <li>Divatte sur Loire (Barbechat/Chapelle-Basse-Mer)</li>
                        <li>St Julien de Concelles</li>
                        <li>Le Loroux Bottereau</li>
                        <li>Landemont</li>
                        <li>St Sauveur de Landemont</li>
                        <li>Thouaré</li>
                        <li>Mauves-sur-Loire</li>
                        <li>La Remaudière</li>
                        <li>La Varenne</li>
                        <!-- <li>Champtoceaux</li>
                        <li>St Laurent des Autels</li>
                        <li>Drain</li>
                        <li>Liré</li>
                        <li>Couffé</li>
                        <li>Ligné</li>
                        <li>Oudon</li>
                        <li>Le Landreau</li> -->
                    </ul>
                </div>
            </div>
            <figure id="plan-intervention" class="d-flex flex-column">
                <h3 style="text-align: center;">Plan des communes desservies</h3>
                <img src="../../assets/plan.webp" alt="Plan d'intervention">
            </figure>
            <div>
                <p class="text-qsn">
                    Nous sommes là pour vous aider de façon ponctuelle ou régulière dans votre quotidien ou autres moments de votre vie.
                </p>
            </div>
        </div>
        <bubble/>
    </article>
</template>

<script>
import bubble from './bubble.vue'

export default {
    name: 'homepresentation',
    components: {
        bubble
    }
}
</script>

<style>
#home-presentation {
    position: relative;
    top: -50px;
    background-color: rgb(255, 255, 255);
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    max-width: 1000px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
    animation: 1.5s textHome;
}

#home-presentation p {
    font-size: 1.1em;
}

#bloc-title-presentation {
    margin-bottom: 40px;
    border-bottom: 3px solid rgb(241, 198, 55);
    width: 100%;
}

.style-title-presentation {
    font-size: 2.3em;
    font-weight: normal;
    background-color: rgba(255, 246, 228, 0.5);
    box-shadow: 0px 0px 25px 1px rgba(128, 128, 128, 0.1);
    text-align: center;
    border-radius: 8px;
    padding: 20px 15px 30px 15px;
    margin-bottom: 30px;
}

.bloc-communes {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}

.cdv-list ul, .rdl-list ul {
    display: flex;
    flex-direction: column;
}

.cdv-list {
    margin-right: 15px;
}

.cdv-list ul li, .rdl-list ul li {
    list-style: disc;
}

.cdv-title, .rdl-title {
    padding-bottom: 10px;
}

.subtitle {
    text-align: center;
}

.bloc-text-home-presentation {
    padding-left: 10px;
    padding-right: 10px;
}

.pict-presentation {
    display: flex;
    width: 60%;
    margin: auto;
    border-radius: 5px;
    margin-top: 20px;
    margin-bottom: 30px;
}

.list-presentation {
    display: flex;
    align-items: center;
    text-align: center;
    list-style: none;
    flex-direction: column;
}

#plan-intervention {
    display: flex;
    justify-content: center;
    margin: 40px 0 40px 0;
    border: 2px solid rgb(221, 178, 178);
    border-radius: 15px;
}

#plan-intervention h3 {
    margin-top: 15px;
}

#plan-intervention img {
    height: 700px;
    width: 500px;
    padding: 15px 0 15px 0;
    margin-top: 15px;
    cursor: zoom-in;
    cursor: -webkit-zoom-in;
    cursor: -moz-zoom-in;
}

#plan-intervention img:hover {
    transform: scale(1.2);
    transition: .3s;
}

/* KeyFrames */
@keyframes textHome {
    from {
        opacity: 0;
        transform: translateY(30px);
    }
    to {
        opacity: 1;
        transform: translateY(0px);
    }
}

@keyframes selecthome {
    0% {
        transform: translateY(-6px);
    }
    50% {
        transform: translateY(6px);
    }
    100% {
        transform: translateY(-6px);
    }
}

@media screen and (max-width: 550px) {
    #plan-intervention img {
        height: 500px;
        width: 350px;
    }
}

@media screen and (max-width: 390px) {
    #plan-intervention img {
        height: 390px;
        width: 270px;
    }
}
</style>