<template>
  <div id="app">
    <header>
      <navigation/>
    </header>
    <router-view/>
    <footer>
      <thisfooter/>
    </footer>
  </div>
</template>

<script>

import home from './components/accueil.vue'
import navigation from './components/navigation.vue'
import thisfooter from './components/pied_de_page.vue'

export default {
  name: 'App',
  home,
  components: {
    navigation,
    thisfooter
  },
  data() {
    return {
      cookie: localStorage.cookie
    }
  },
  methods: {
    cookieOk() {
      localStorage.cookie = "1";
      location.reload();
    }
  }
}
</script>

<style>
body {
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

h1 {
    font-size: 3em;
    margin-bottom: 0;
    padding-top: 25px;
    padding-bottom: 15px;
    color: #374857;
}

h2 {
    font-size: 1.4em;
    padding-bottom: 35px;
    font-weight: bold;
    margin: 0;
    color: rgb(0, 0, 0);
}

.text-align {
  text-align: center;
}

.flex-column {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.border-bottom {
  border-bottom: 2px solid #556c7f!important;
}

/* KeyFrames */
@keyframes textX {
    0% {
      opacity: 0;
    }
    15% {
      opacity: 0;
      transform: translateX(-50px);
    }
    100% {
      opacity: 1;
      transform: translateX(0px);
    }
}

@keyframes textX2 {
    0% {
      opacity: 0;
    }
    15% {
      opacity: 0;
      transform: translateX(50px);
    }
    100% {
      opacity: 1;
      transform: translateX(0px);
    }
}

@keyframes textY {
    from {
      opacity: 0;
      transform: translateY(30px);
    }
    to {
      opacity: 1;
      transform: translateY(0px);
    }
}
</style>
