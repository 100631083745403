<template>
    <div id="services-container">
        <div id="presta-titre">
            <h1 class="bk-qsn" id="presta-titre-h1">Nos services</h1>
        </div>
        <div id="container-presta-text">
            <h2 class="size-h2">Services proposés</h2>
            <h3 class="text-align border-bottom">Aide à la vie sociale</h3>
            <ul class="list-presta">
                <li>Partage de vos activités de loisirs (jardinage, sorties, jeux de société, lecture...)</li>
                <li>Accompagnement lors de vos déplacements (rendez-vous, courses, promenades, évènement familial)</li>
                <li>Aide et démarches administratives</li>
                <li>Répit aux aidants en prennant soin de la personne fragilisée</li>
            </ul>
            <figure class="img-bloc-1">
                <img class="img-presta-1" src="../../assets/justine_et_huguette.jpeg" alt="Justine (membre du collectif) et Huguette" title="Justine (membre du collectif) et Huguette">
                <figcaption>Justine et Huguette</figcaption>
            </figure>
            <h3 class="text-align border-bottom">Accompagnement dans les gestes de la vie quotidienne</h3>
            <ul class="list-presta list-presta-2">
                <li>Préparation et aide aux repas</li>
                <li>Aide à la toilette, à la mobilisation</li>
                <li>Entretien du cadre de vie</li>
            </ul>
            <p class="questions-presta text-align">Si vous avez des questions sur les services que nous proposons, n'hésitez pas à nous contacter.</p>
        </div>
        <div class="en-savoir-plus">
        <div>
            <span class="border-span"></span>
            <div class="bloc-all-square">
                <div class="bloc-to-square">
                    <a class="bloc-square bloc1" title="Retourner à l'accueil" href="/">ACCUEIL</a>
                    <a class="bloc-square bloc2" title="Qui sommes nous ?" href="/quisommesnous">QUI SOMMES NOUS ?</a>
                </div>
                <div class="bloc-to-square">
                    <a class="bloc-square bloc3" title="Les témoignages" href="/temoignages">TEMOIGNAGES</a>
                    <a class="bloc-square bloc4" title="Nos tarifs et contrats" href="/tarifs">TARIFS ET CONTRATS</a>
                </div>
            </div>
            <div class="bloc-all-square-tel">
                <a class="bloc1-tel" title="Retourner à l'accueil" href="/">ACCUEIL</a>
                <a class="bloc2-tel" title="Qui sommes nous ?" href="/quisommesnous">QUI SOMMES NOUS ?</a>
                <a class="bloc3-tel" title="Les témoignages" href="/temoignages">TEMOIGNAGES</a>
                <a class="bloc4-tel" title="Nos tarifs et contrats" href="/tarifs">TARIFS ET CONTRATS</a>
            </div>
            <span class="border-span2"></span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'presta'
}
</script>

<style>
#services-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    animation: 1s textY;
}

#presta-titre {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: center url("../../assets/1920x1280-mains.jpg");
    background-size: cover;
    background-attachment: fixed;
    text-align: center;
    width: 100%;
    height: 550px;
    margin-bottom: 30px;
}

#container-presta-text {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    background-color: white;
    top: -80px;
    width: 100%;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    min-width: 280px;
    max-width: 1000px;
}

.img-bloc-1 {
    text-align: center;
    margin-top: 25px;
    margin-bottom: 20px;
}

.img-presta-1 {
    width: 350px;
    border-radius: 5px;
    box-shadow: 0px 0px 40px 1px rgba(0, 0, 0, 0.4);
}

#container-presta-text h3 {
    margin-bottom: 15px;
}

.border-bottom {
    padding-left: 5px;
    padding-right: 5px;
}

.size-h2 {
    font-size: 2.5em;
    font-weight: normal;
    background-color: rgb(254, 255, 220);
    box-shadow: 0px 0px 25px 1px rgba(128, 128, 128, 0.5);
    width: 100%;
    text-align: center;
    border-radius: 8px;
    padding-top: 20px;
    padding-bottom: 30px;
    margin-bottom: 30px;
}

#presta-titre-h1 {
    background-color: rgba(0, 0, 0, 0.3);
    color: white;
    border-bottom: 2px solid rgba(0, 0, 0, 0.4);
}

.list-presta {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    list-style: unset;
    padding: 0;
    margin-left: 12px;
    margin-right: 12px;
}

.list-presta-2 {
    flex-direction: row;
    flex-wrap: wrap;
}

.list-presta-2 li {
    margin-left: 15px;
    margin-right: 15px;
}

.list-presta li {
    font-size: 1.1em;
}

.list-presta a {
    flex-wrap: wrap;
    padding: 12px 32px 12px 32px;
    margin: 5px;
    text-align: center;
    color: black;   
}

.list-top {
    position: relative;
    top: -50px;
}

.en-savoir-plus a, .questions-presta {
    margin-top: 20px;
    margin-bottom: 0px;
}

@media screen and (max-width: 480px) {
    #presta-titre {
        background-attachment: scroll;
    }
}

@media screen and (max-width: 450px) {

    .bloc-all-square {
        display: none!important;
    }

    .img-presta-1 {
        width: 270px;
    }

    .en-savoir-plus div {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}
</style>