import { render, staticRenderFns } from "./accueil_contenu.vue?vue&type=template&id=3b05d14f"
import script from "./accueil_contenu.vue?vue&type=script&lang=js"
export * from "./accueil_contenu.vue?vue&type=script&lang=js"
import style0 from "./accueil_contenu.vue?vue&type=style&index=0&id=3b05d14f&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports