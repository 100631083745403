<template>
    <div id="contact-container">
        <div id="title-contact">
            <h1>Formulaire de contact</h1>
            <h2>Veuillez remplir le formulaire ci-dessous afin de prendre contact avec notre équipe</h2>
        </div>
        <div id="form-container">
            <div id="img-contact-container">
                <img src="../../assets/contact.jpg" title="Formulaire de contact" alt="Personne envoyant un courrier">
            </div>
            <form id="form-contact" method="POST">
                <div v-if="dataContact.email.length > 5 && dataContact.text.length > 5">
                    <p v-for="(error, number) in regex.errors" v-bind:key="number" class="error">{{ regex.errors[number] }}</p>
                </div>
                <div id="name-bloc">
                    <div class="div-input-form">
                        <label class="label-form" for="firstname"><strong>Prénom*</strong></label>
                        <input @keyup="checkForm" v-model="dataContact.firstname" class="input-form input-contact" type="text" id="firstname" name="firstname" minlength="2" maxlength="25" placeholder="Prénom" autofocus required>
                    </div>
                    <div class="div-input-form">
                        <label class="label-form" for="lastname"><strong>Nom*</strong></label>
                        <input @keyup="checkForm" v-model="dataContact.lastname" class="input-form input-contact" type="text" id="lastname" name="lastname" minlength="2" maxlength="25" placeholder="Nom" required>
                    </div>
                </div>
                <div id="contact-bloc">
                    <div class="div-input-form">
                        <label class="label-form" for="email"><strong>Adresse e-mail*</strong></label>
                        <input @keyup="checkForm" v-model="dataContact.email" class="input-form input-contact" type="email" id="email" name="email" minlength="10" maxlength="45" placeholder="email@email.fr" required>
                    </div>
                    <div class="div-input-form">
                        <label class="label-form" for="phone"><strong>Téléphone*</strong></label>
                        <input @keyup="checkForm" v-model="dataContact.phone" class="input-form input-contact" type="text" id="phone" name="phone" maxlength="10" minlength="10" placeholder="0611223344" required>
                    </div>
                </div>
                <div class="text-align" style="margin-bottom: 15px;">
                    <label for="sector-select" class="label-form"><strong>Secteur concerné*</strong></label>
                    <select v-model="dataContact.sector" name="sector-select" required>
                        <option class="text-align" value="1">Coeur du Vignoble</option>
                        <option class="text-align" value="2">Rives de loire</option>
                    </select>
                </div>
                <div id="objet-bloc">
                    <div class="container-infos">
                        <label class="label-form-textarea" for="zonetext"><strong>Votre demande* </strong></label>
                        <div class="infos">
                            <button @click="displayInfos" class="btn btn-light btn-sm btn-infos-contact" type="button" title="Afficher les informations"><img src="../../assets/svg/info-circle-solid.svg" width="14px" height="14px" alt="info"></button>
                            <p v-if="infos == true" class="text">Votre demande doit être courte. Une personne du collectif se chargera de vous rappeler dans les plus brefs délais<br> <button @click="displayInfos" class="btn-infos-contact-2" title="Fermer le panneau d'informations">Fermer</button></p>
                        </div>
                    </div>
                    <textarea @keyup="checkForm" v-model="dataContact.text" id="objet-text" name="zonetext" minlength="25" maxlength="1500" placeholder="Bonjour..." required></textarea>
                </div>
                <label class="pp_contact text-align" style="margin-left: 5px; margin-right: 5px; margin-top: 15px;"><strong>En envoyant le message, vous acceptez la politique de confidentialité.</strong></label>
                <div v-if="errorsMail.length > 5">
                    <p class="error">{{ errorsMail }}</p>
                </div>
                <div v-if="okMail.length > 5">
                    <p class="green text-align">{{ okMail }}</p>
                </div>
                <div id="send-form">
                    <button @click="sendEmail" :disabled="regex.disabled == true" type="button" class="btn btn-info btn-send-form" title="Enovoyer mon message">Envoyer</button>
                    <button type="reset" class="btn btn-dark btn-sm btn-send-form" title="Remettre à 0 le formulaire">Effacer</button>
                </div>
                <div class="mention-contact">
                    <i>* : Les champs doivent être remplis</i>
                    <a href="/privacypolicy" title="Afficher la politique de confidentialité">Lire la politique de confidentialité</a>
                </div>
            </form>
        </div>
        <div class="autre-contact">
            <h5>Vous pouvez aussi nous contacter par mail ou téléphone: </h5>
            <p>Secteur Rives de Loire : <a href="mailto:collectifcouleursdelavie@gmail.com" title="Secteur Rive de Loire">collectifcouleursdelavie@gmail.com</a> ou au <a href="tel:0695785284" title="Téléphone Rive de Loire">06.95.78.52.84</a></p>
            <p>Secteur Coeur du Vignoble : <a href="mailto:collectiflescouleursdelavie@gmail.com" title="Secteur Coeur du Vignoble">collectiflescouleursdelavie@gmail.com</a> ou au <a href="tel:0645463964">06.45.46.39.64</a></p>
        </div>
    </div>
</template>

<script>
import axios from "axios"

export default {
    name: 'contact',
    data() {
        return {
            infos: false,
            regex: {
                disabled: true,
                errors: []
            },
            dataContact: {
                firstname: "",
                lastname: "",
                email: "",
                phone: "",
                text: "",
                sector: ""
            },
            errorsMail: "",
            okMail: ""
        }
    },
    methods: {
        sendEmail() {
            let data = JSON.stringify(this.dataContact);
            axios.post("https://collectiflescouleursdelavie.fr:3000/kGKyJbyf03SnVE6pzEP7gL8J1vpqE1zm5PBUvt0pCfVjnjmTjF", data, {headers: {'Content-Type': 'application/json'}})
            .then(() => {
                this.okMail = "Le message a bien été envoyé ! Merci";
                this.dataContact.firstname = "";
                this.dataContact.lastname = "";
                this.dataContact.email = "";
                this.dataContact.phone = "";
                this.dataContact.text = "";
            })
            .catch(() => {
                this.errorsMail = "Le message n'a pas pu être envoyé. Merci de nous contacter par mail.";
            })
        },
        displayInfos() {
            if(this.infos == true) {
                this.infos = false;
            } else if (this.infos == false) {
                this.infos = true;
            }
        },
        checkForm:function() {
            this.regex.errors = [];
            if (this.regex.errors.length == 0) {
            this.regex.disabled = false;
            }
            // Prénom
            if (!this.dataContact.firstname) {
                this.regex.errors.push("Le prénom doit être indiqué.");
                this.regex.disabled = true;
            } else if (!this.verificationFirstname(this.dataContact.firstname)) {
                this.regex.errors.push("Format du prénom incorrect.");
                this.regex.disabled = true;
            }
            // Nom
            if (!this.dataContact.lastname) {
                this.regex.errors.push("Le nom doit être indiqué.");
                this.regex.disabled = true;
            } else if (!this.verificationLastname(this.dataContact.lastname)) {
                this.regex.errors.push("Format du nom incorrect.");
                this.regex.disabled = true;
            }
            // Adresse e-mail
            if (!this.dataContact.email) {
                this.regex.errors.push("L'e-mail doit être indiqué.");
                this.regex.disabled = true;
            } else if (!this.verificationEmail(this.dataContact.email)) {
                this.regex.errors.push("Format d'e-mail incorrect.");
                this.regex.disabled = true;
            }
            // Téléphone
            if (!this.dataContact.phone) {
                this.regex.errors.push("Le téphone doit être indiqué.");
                this.regex.disabled = true;
            } else if (!this.verificationPhone(this.dataContact.phone)) {
                this.regex.errors.push("Format de téléphone incorrect.");
                this.regex.disabled = true;
            }
            // Texte de demande
            if (!this.dataContact.text) {
                this.regex.errors.push("Un texte de 25 caractères est demandé, sans caractères spéciaux ($*^&...).");
                this.regex.disabled = true;
            } else if (!this.verificationTextarea(this.dataContact.text)) {
                this.regex.errors.push("Un texte de 25 caractères est demandé, sans caractères spéciaux ($*^&...).");
                this.regex.disabled = true;
            }
        },
        verificationFirstname:function(firstname) {
            this.dataContact.firstname = firstname;
            //eslint-disable-next-line
            let reFirstname = /^[\w'\-,.][^0-9_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\]]{2,24}$/;
            return reFirstname.test(firstname);
        },
        verificationLastname:function(lastname) {
            this.dataContact.lastname = lastname;
            //eslint-disable-next-line
            let reLastname = /^[\w'\-,.][^0-9_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\]]{2,24}$/;
            return reLastname.test(lastname);
        },
        verificationEmail:function(email) {
            this.dataContact.email = email;
            //eslint-disable-next-line
            let reEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return reEmail.test(email);
        },
        verificationPhone:function(phone) {
            this.dataContact.phone = phone;
            //eslint-disable-next-line
            let rePhone = /^(?:(?:\+|00)33|0)\s*[1-9](?:[\s.-]*\d{2}){4}$/;
            return rePhone.test(phone);
        },
        verificationTextarea:function(textarea) {
            this.dataContact.text = textarea;
            //eslint-disable-next-line
            let reText = /^[\w'\-,.0-9][^_\\#$%ˆ&*{}|~<>[\]]{25,1500}$/;
            return reText.test(textarea);
        }
    }
}
</script>

<style>
#form-contact {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 20px;
    margin-top: 40px;
    padding: 10p;
    padding-top: 30px;
    padding-bottom: 30px;
    border-radius: 15px;
    box-shadow: 0px 0px 20px 1px rgb(233, 233, 233);
    animation: 2s formulaire;
}

#form-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
}

#title-contact h1, #title-contact h2{
    text-align: center;
    padding: 0;
    margin-top: 15px;
}

#name-bloc, #contact-bloc {
    display: flex;
    flex-wrap: wrap;
}

#img-contact-container {
    max-width: 390px;
    max-height: 700px;
}

.div-input-form, #objet-bloc {
    display: flex;
    flex-direction: column;
}

.label-form {
    margin-left: 20px;
    margin-right: 20px;
    color: rgb(77, 81, 85);
}

.container-infos {
    display: flex;
}

.container-infos label {
    margin-right: 5px;
}

.btn-infos-contact {
    padding: 0;
    background-color: white;
    color: rgb(8, 84, 248);
    border: 0;
    width: 20px;
}

.btn-infos-contact-2 {
    display: flex;
    margin: auto;
    padding: 0;
    background-color: transparent;
    color: rgb(8, 84, 248);
    border: 0;
}

.btn-infos-contact-2 i {
    margin-left: 5px;
    padding-top: 3px;
}

.infos {
    text-align: center;
    display: inline-block;
    position: relative;
}

.infos p.text {
    z-index: 1;
    color: rgb(0, 0, 0);
    width: 213px;
    height: 160px;
    padding: 10px;
    background: rgba(255, 255, 255, 0.9);
    box-shadow: 0px 0px 20px 2px rgb(206, 206, 206);
    margin: 0;
    left: 20px;
    transition: .5s;
    border-radius: 5px;
    top: 0;
    opacity: 1;
    display: block;
    position: absolute;
}

.red {
    padding-top: 2px;
    padding-left: 5px;
    padding-right: 5px;
    border-radius: 3px;
    color: crimson;
    font-size: 1.3em;
}

.green {
    padding-top: 2px;
    padding-left: 5px;
    padding-right: 5px;
    border-radius: 3px;
    color: green;
    background-color: rgba(0, 128, 0, 0.2);
    font-size: 1.3em;
}

#objet-bloc {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

#objet-text {
    width: 340px;
    min-width: 200px;
    max-width: 700px;
    min-height: 100px;
    max-height: 300px;
    border: 0;
    border-bottom: 2px solid black;
    border-radius: 2px;
    background-color: rgba(230, 230, 230, 0.3);
}

::placeholder {
    color: black;
}

.input-form {
    border: 0;
    border-bottom: 2px solid black;
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 30px;
}

.input-form:hover {
    border-bottom: 2px dashed green;
}

.input-form:focus {
    outline: none;
    border: 2px solid rgb(110, 110, 110);
    border-radius: 2px;
}

.form-check {
    margin-top: 15px;
}

.btn-send-form {
    margin: 15px;
}

.mention-contact {
    display: flex;
    flex-direction: column;
    text-align: center;
}

.autre-contact {
    text-align: center;
    padding-left: 10px;
    padding-right: 10px;
}

@media screen and (max-width: 890px) {

    #form-container {
        justify-content: center;
    }

    #img-contact-container {
        display: none;
    }
}

@media screen and (max-width: 480px) {

    #form-contact {
        width: 100%;
    }

    .input-contact {
        width: 250px;
        margin-left: 5px;
        margin-right: 5px;
    }

    .infos p.text {
        top: 32px;
        left: -95px;
    }

    #objet-text {
        max-width: 400px;
        width: 100%;
        min-width: 250px;
        height: 200px;
    }

    #name-bloc, #contact-bloc {
        flex-direction: column;
    }

    .pp_contact {
        width: 180px;
        text-align: center;
    }
}

/* KeyFrames */
@keyframes formulaire {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
</style>