<template>
    <div id="login-container">
        <div>
            <h1>Espace membre</h1>
        </div>
        <div>
            <form id="form-connect">
                <h6>Remplissez les champs pour vous connecter à l'espace membre</h6>
                <p v-if="errors.length > 0" class="red"> {{ errors[0] }} </p>
                <div v-if="dataLogin.email.length > 8 && dataLogin.password.length > 5">
                    <p v-for="(error, number) in regex.errors" v-bind:key="number" class="error">{{ regex.errors[number] }}</p>
                </div>
                <div>
                    <label class="label-form">Adresse e-mail</label>
                    <input @keyup="checkForm" v-model="dataLogin.email" class="input-form input-login" type="email" minlength="10" maxlength="45" placeholder="email@email.fr" autofocus autocomplete="username" required>
                </div>
                <div>
                    <label class="label-form">Mot de passe</label>
                    <div class="eye-bloc">
                        <input @keyup="checkForm" v-model="dataLogin.password" id="look" class="input-form input-login" type="password" minlength="8" maxlength="25" placeholder="••••••••••" autocomplete="current-password" required>
                        <button @click="eyeDisplay" v-if="eye == false" type="button"  class="eye btn-sm"><img src="../../assets/svg/eye-solid.svg" width="17px" height="17px" alt="yeux afficher mot de passe" title="Afficher le mot de passe"></button>
                        <button @click="eyeDisplay" v-if="eye == true" type="button" class="eye btn-sm"><img src="../../assets/svg/eye-slash-solid.svg" width="17px" height="17px" alt="yeux cacher le mot de passe" title="Cacher le mot de passe"></button>
                    </div>
                </div>
                <button @click="connect" :disabled="regex.disabled == true" type="button" class="btn btn-success btn-send-connexion" title="Me connecter à l'espace membre">Connexion</button>
            </form>
        </div>
    </div>
</template>

<script>
import axios from "axios"

export default {
    name: 'login',
    data() {
        return {
            regex: {
                disabled: true,
                errors: []
            },
            dataLogin: {
                email: "",
                password: ""
            },
            errors: [],
            eye: false
        }
    },
    methods: {
        eyeDisplay() {
            if(this.eye == true) {
                this.eye = false;
                this.notLook();

            } else if (this.eye == false) {
                this.eye = true;
                this.look();
            }
        },
        look() {
            let a = document.getElementById('look');
            a.setAttribute('type', 'text');
        },
        notLook() {
            let a = document.getElementById('look');
            a.setAttribute('type', 'password');
        },
        connect() {
            this.errors = [];
            const data = JSON.stringify(this.dataLogin);
            axios.post("https://collectiflescouleursdelavie.fr:3000/Xw9LpmRjYxVcxDZlKMpL5ItytsXEo78oBLD", data, {headers: {'Content-Type': 'application/json'}})
            .then(response => {
                let dataRecup = response.data;
                let red = dataRecup.r;
                localStorage.nom = dataRecup.pr;
                localStorage.prenom = dataRecup.no;
                localStorage.token = dataRecup.to;
                localStorage.DsPZBSOxyP6m1Tc = dataRecup.ve;
                this.$router.push(red);
            })
            .catch(() => {
                this.errors.push("Email ou mot de passe incorrect.");
            })
        },
        checkForm:function() {
            this.regex.errors = [];
            if (this.regex.errors.length == 0) {
                this.regex.disabled = false;
            }
            if (!this.dataLogin.email) {
                this.regex.errors.push("L'email est nécessaire.");
                this.regex.disabled = true;
            } else if (!this.verificationEmail(this.dataLogin.email)) {
                this.regex.errors.push("Format d'e-mail incorrect.");
                this.regex.disabled = true;
            }
            if (!this.dataLogin.password) {
                this.regex.errors.push("Le mot de passe est nécessaire.");
                this.regex.disabled = true;
            }
            if(!this.verificationPassword(this.dataLogin.password)) {
                this.regex.errors.push("Le mot de passe doit contenir au moins 8 caractères.");
                this.regex.disabled = true;
            }
        },
        verificationEmail:function(email) {
            this.dataLogin.email = email;
            //eslint-disable-next-line
            let reEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return reEmail.test(email);
        },
        verificationPassword:function(pass) {
            this.dataLogin.password = pass;
            //eslint-disable-next-line
            let rePass = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/;
            return rePass.test(pass);
        }
    }
}
</script>

<style>
#login-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

#login-container div h1 {
    background-color: rgba(255, 255, 255, 0);
}

#form-connect {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 40px;
    padding-top: 30px;
    padding-left: 15px;
    padding-right: 15px;
    margin-bottom: 60px;
    box-shadow: 0px 0px 20px 2px rgb(199, 199, 199);
    border-radius: 15px;
    max-width: 315px;
}

#form-connect div {
    display: flex;
    flex-direction: column;
    align-items: center;
}

#form-connect h6 {
    max-width: 300px;
    text-align: center;
    margin: auto;
    margin-bottom: 30px;
}

.input-login {
    width: 220px;
}

.btn-send-connexion {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
}

.btn-send-connexion i {
    margin-left: 7px;
    padding-top: 2px;
}

.error {
    color: rgb(218, 0, 0);
    background-color: rgb(255, 230, 230);
    text-align: center;
    font-weight: bold;
}

.eye-bloc {
    display: flex;
}

.eye {
    position: relative;
    border: 0;
    background-color: transparent;
    top: -60px;
    left: 95px;
}

.eye:focus{
    outline: 0px;
    background-color: transparent;
}
</style>