<template>
    <div>
        <span class="border-span"></span>
        <div class="bloc-all-square">
            <div class="bloc-to-square">
                <a class="bloc-square bloc1" title="Nos services" href="/services">NOS SERVICES</a>
                <a class="bloc-square bloc2" title="Qui sommes nous ?" href="/quisommesnous">QUI SOMMES NOUS ?</a>
            </div>
            <div class="bloc-to-square">
                <a class="bloc-square bloc3" title="Les témoignages" href="/temoignages">TEMOIGNAGES</a>
                <a class="bloc-square bloc4" title="Tarifs et contrats" href="/tarifs">TARIFS ET CONTRATS</a>
            </div>
        </div>
        <div class="bloc-all-square-tel">
            <a class="bloc1-tel" title="Nos services" href="/services">NOS SERVICES</a>
            <a class="bloc2-tel" title="Qui sommes nous ?" href="/quisommesnous">QUI SOMMES NOUS ?</a>
            <a class="bloc3-tel" title="Les témoignages" href="/temoignages">TEMOIGNAGES</a>
            <a class="bloc4-tel" title="Tarifs et contrats" href="/tarifs">TARIFS ET CONTRATS</a>
        </div>
        <span class="border-span2"></span>
    </div>
</template>

<script>
export default {
    name: 'bubble'
}
</script>

<style>
.bloc-all-square-tel {
    display: none;
}

.bloc-all-square {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 15px;
    padding-top: 30px;
    padding-bottom: 30px;
}

.bloc-to-square {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.bloc-square {
    align-content: center;
    min-width: 200px;
    min-height: 200px;
    max-height: 200px;
    max-width: 200px;
}

.bloc1, .bloc2, .bloc3, .bloc4 {
    display: flex;
    font-size: 1.6em;
    flex-wrap: wrap;
    text-align: center;
    justify-content: center;
    color: rgb(45, 45, 73);
    box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.1);
    margin: 2px;
    padding: 10px;
    border-radius: 50%;
}

.bloc1:hover, .bloc2:hover, .bloc3:hover, .bloc4:hover {
    text-decoration: none;
    transition: .5s;
    box-shadow: 0px 0px 15px 1px rgba(0, 0, 0, 0.2);
    color: white;
}

.bloc1:hover {
    background-color: rgba(255, 166, 0, 0.5);
}

.bloc2:hover {
    background-color: rgba(31, 187, 31, 0.6);
}

.bloc3:hover {
    background-color: rgba(214, 28, 231, 0.5);
}

.bloc4:hover {
    background-color: rgba(0, 132, 255, 0.6);
}

.bloc1 {
    background-color: rgba(255, 166, 0, 0.3);
    animation: 5s selecthome infinite;
}

.bloc2 {
    background-color: rgba(31, 187, 31, 0.3);
    animation: 5s selecthome 0.8s infinite;
}

.bloc3 {
    background-color: rgba(214, 28, 231, 0.3);
    animation: 5s selecthome 1.2s infinite;
}

.bloc4 {
    background-color: rgba(0, 132, 255, 0.4);
    animation: 5s selecthome 1.7s infinite;
}

.border-span {
    width: 280px;
    height: 2px;
    background-color: black;
}

.border-span2 {
    width: 80px;
    height: 2px;
    background-color: black;
}

@media screen and (max-width: 890px) {
    .bloc-square {
        align-content: center;
        min-width: 150px;
        min-height: 150px;
        max-height: 150px;
        max-width: 150px;
        font-size: 1.3em;
    } 
}

@media screen and (max-width: 680px) {
    .bloc-all-square, .border-span, .border-span2 {
        display: none;
    }

    .bloc-all-square-tel {
        display: flex;
        align-items: center;
        flex-direction: column;
    }

    .bloc1-tel:hover {
        background-color: rgba(255, 166, 0, 0.5);
    }

    .bloc2-tel:hover {
        background-color: rgba(31, 187, 31, 0.6);
    }

    .bloc3-tel:hover {
        background-color: rgba(214, 28, 231, 0.5);
    }

    .bloc4-tel:hover {
        background-color: rgba(0, 132, 255, 0.6);
    }

    .bloc1-tel {
        background-color: rgba(255, 166, 0, 0.3);
    }

    .bloc2-tel {
        background-color: rgba(31, 187, 31, 0.3);
    }

    .bloc3-tel {
        background-color: rgba(214, 28, 231, 0.3);
    }

    .bloc4-tel {
        background-color: rgba(0, 132, 255, 0.4);
    }

    .bloc-all-square-tel a {
        padding: 12px 32px 12px 32px;
        margin: 5px;
        width: 250px;
        text-align: center;
        color: black;
    }

    .bloc-all-square-tel a:hover {
        text-decoration: none;
    }
}
</style>