<template>
    <div id="temoignages-container">
        <div class="testimony-titre">
            <h1 class="testimony-h1">Vos témoignages</h1>
        </div>
        <article id="container-testi-text">
            <h2 class="subtitle-testi">Vos avis sont précieux</h2>
            <div class="select-display-form">
                <button @click="checkDisplay" v-if="displayForm == false" class="btn btn-success btn-lg btn-ajust">Laisser mon avis</button>
                <button @click="checkDisplay" v-if="displayForm == true" class="btn btn-danger btn-lg">Fermer le formulaire</button>
            </div>
            <form id="form-contact" class="form-testi-ajust" method="POST" v-if="displayForm == true">
                <h3 class="title-form-testi">Complètez le formulaire pour laisser un avis</h3>
                <button @click="checkDisplay" title="Fermer la fenêtre" type="button" class="btn btn-danger btn-sm btn-testi-close">Fermer le formulaire</button>
                <div v-if="dataTesti.email.length > 5 && dataTesti.text.length > 5">
                    <p v-for="(error, number) in regex.errors" v-bind:key="number" class="error">{{ regex.errors[number] }}</p>
                </div>
                <div id="name-bloc">
                    <div class="div-input-form">
                        <label class="label-form" for="firstname"><strong>Prénom*</strong></label>
                        <input @keyup="checkForm" v-model="dataTesti.firstname" class="input-form input-contact" type="text" id="firstname" name="firstname" minlength="2" maxlength="25" placeholder="Prénom" autofocus required>
                    </div>
                    <div class="div-input-form">
                        <label class="label-form" for="lastname"><strong>Nom*</strong></label>
                        <input @keyup="checkForm" v-model="dataTesti.lastname" class="input-form input-contact" type="text" id="lastname" name="lastname" minlength="2" maxlength="25" placeholder="Nom" required>
                    </div>
                </div>
                <div id="contact-bloc">
                    <div class="div-input-form">
                        <label class="label-form" for="email"><strong>Adresse e-mail*</strong></label>
                        <input @keyup="checkForm" v-model="dataTesti.email" class="input-form input-contact" type="email" id="email" name="email" minlength="10" maxlength="45" placeholder="email@email.fr" required>
                    </div>
                    <div class="div-input-form">
                        <label class="label-form" for="phone"><strong>Téléphone*</strong></label>
                        <input @keyup="checkForm" v-model="dataTesti.phone" class="input-form input-contact" type="text" id="phone" name="phone" maxlength="10" minlength="10" placeholder="0611223344" required>
                    </div>
                </div>
                <div id="objet-bloc">
                    <div class="container-infos">
                        <label class="label-form-textarea" for="zonetext"><strong>Votre texte* </strong></label>
                    </div>
                    <textarea @keyup="checkForm" v-model="dataTesti.text" id="objet-text" name="zonetext" minlength="25" maxlength="1500" placeholder="Bonjour..." required></textarea>
                </div>
                <div class="form-check">
                    <label class="form-check-label pp_contact"><strong>En postant votre témoignage, vous acceptez la politique de confidentialité.</strong></label>
                </div>
                <div id="send-form">
                    <button @click="sendTestimony" :disabled="regex.disabled == true" type="button" class="btn btn-info btn-send-form" title="Enovoyer mon témoignage">Envoyer</button>
                    <button type="reset" class="btn btn-dark btn-sm btn-send-form" title="Remettre à 0 le formulaire">Effacer</button>
                </div>
                <div class="mention-contact">
                    <i>* : Les champs doivent être remplis</i>
                    <a href="/privacypolicy" title="Afficher la politique de confidentialité">Lire la politique de confidentialité</a>
                </div>
            </form>
            <section id="posts-testi">
                <h3 v-if="getDataTesti.allPosts.length >= 1" class="text-align">Ils ont laissé leurs avis...</h3>
                <h3 v-if="getDataTesti.allPosts.length == 0" class="text-align">Soyez le premier à laisser un avis !</h3>
            </section>
            <section id="section-posts" v-for="(post, listPosts) in getDataTesti.allPosts" v-bind:key="listPosts">
                <div id="posts-list">
                    <div class="posts-list-title">
                        <h5><strong> {{ post.firstname }} le </strong></h5>
                        <h5><strong> {{ post.date }} </strong></h5>
                    </div>
                    <div class="box-txt">
                        <span class="txt">{{ post.text }}</span>
                    </div>
                </div>
            </section>
        </article>
        <div>
            <span class="border-span"></span>
            <div class="bloc-all-square">
                <div class="bloc-to-square">
                    <a class="bloc-square bloc1" title="Retourner à l'accueil" href="/">ACCUEIL</a>
                    <a class="bloc-square bloc2" title="Nos services" href="/services">NOS SERVICES</a>
                </div>
                <div class="bloc-to-square">
                    <a class="bloc-square bloc3" title="Qui sommes nous ?" href="/quisommesnous">QUI SOMMES NOUS ?</a>
                    <a class="bloc-square bloc4" title="Tarifs et contrats" href="/tarifs">TARIFS ET CONTRATS</a>
                </div>
            </div>
            <div class="bloc-all-square-tel">
                <a class="bloc1-tel" title="Retourner à l'accueil" href="/">ACCUEIL</a>
                <a class="bloc2-tel" title="Nos services" href="/services">NOS SERVICES</a>
                <a class="bloc3-tel" title="Qui sommes nous ?" href="/quisommesnous">QUI SOMMES NOUS ?</a>
                <a class="bloc4-tel" title="Tarifs et contrats" href="/tarifs">TARIFS ET CONTRATS</a>
            </div>
            <span class="border-span2"></span>
        </div>
    </div>
</template>

<script>
import axios from "axios"

export default {
    name: 'testimony',
    data() {
        return {
            dataTesti: {
                firstname: "",
                lastname: "",
                email: "",
                phone: "",
                text: "",
            },
            getDataTesti: {
                allPosts: []
            },
            regex: {
                errors: [],
                disabled: true
            },
            displayForm: false,
        }
    },
    methods: {
        sendTestimony() {
            const data = JSON.stringify(this.dataTesti);
            axios.post("https://collectiflescouleursdelavie.fr:3000/sendMessage", data, {headers: {'Content-Type': 'application/json'}})
            .then(() => {
                location.reload();
            })
            .catch(() => {
                location.reload();
            })
        },
        checkDisplay() {
            if(this.displayForm == true) {
                this.displayForm = false;
            } else if (this.displayForm == false) {
                this.displayForm = true;
            }
        },
        checkForm:function() {
            this.regex.errors = [];
            if (this.regex.errors.length == 0) {
            this.regex.disabled = false;
            }
            //PC
            if (this.check == false) {
                this.regex.errors.push("Veuillez accepter la politique de confidentialité.")
            }
            // Prénom
            if (!this.dataTesti.firstname) {
                this.regex.errors.push("Le prénom doit être indiqué.");
                this.regex.disabled = true;
            } else if (!this.verificationFirstname(this.dataTesti.firstname)) {
                this.regex.errors.push("Format du prénom incorrect.");
                this.regex.disabled = true;
            }
            // Nom
            if (!this.dataTesti.lastname) {
                this.regex.errors.push("Le nom doit être indiqué.");
                this.regex.disabled = true;
            } else if (!this.verificationLastname(this.dataTesti.lastname)) {
                this.regex.errors.push("Format du nom incorrect.");
                this.regex.disabled = true;
            }
            // Adresse e-mail
            if (!this.dataTesti.email) {
                this.regex.errors.push("L'e-mail doit être indiqué.");
                this.regex.disabled = true;
            } else if (!this.verificationEmail(this.dataTesti.email)) {
                this.regex.errors.push("Format d'e-mail incorrect.");
                this.regex.disabled = true;
            }
            // Téléphone
            if (!this.dataTesti.phone) {
                this.regex.errors.push("Le téphone doit être indiqué.");
                this.regex.disabled = true;
            } else if (!this.verificationPhone(this.dataTesti.phone)) {
                this.regex.errors.push("Format de téléphone incorrect.");
                this.regex.disabled = true;
            }
            // Texte de demande
            if (!this.dataTesti.text) {
                this.regex.errors.push("Un texte de 25 caractères est demandé.");
                this.regex.disabled = true;
            } else if (!this.verificationTextarea(this.dataTesti.text)) {
                this.regex.errors.push("Veuillez entrer un texte.");
                this.regex.disabled = true;
            }
        },
        verificationFirstname:function(firstname) {
            this.dataTesti.firstname = firstname;
            //eslint-disable-next-line
            let reFirstname = /^[\w'\-,.][^0-9_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\]]{2,24}$/;
            return reFirstname.test(firstname);
        },
        verificationLastname:function(lastname) {
            this.dataTesti.lastname = lastname;
            //eslint-disable-next-line
            let reLastname = /^[\w'\-,.][^0-9_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\]]{2,24}$/;
            return reLastname.test(lastname);
        },
        verificationEmail:function(email) {
            this.dataTesti.email = email;
            //eslint-disable-next-line
            let reEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return reEmail.test(email);
        },
        verificationPhone:function(phone) {
            this.dataTesti.phone = phone;
            //eslint-disable-next-line
            let rePhone = /^(?:(?:\+|00)33|0)\s*[1-9](?:[\s.-]*\d{2}){4}$/;
            return rePhone.test(phone);
        },
        verificationTextarea:function(textarea) {
            this.dataTesti.text = textarea;
            //eslint-disable-next-line
            let reText = /^[\w'\-,.0-9][^_\\#$%ˆ&*{}|~<>[\]]{25,1499}$/;
            return reText.test(textarea);
        },
    },
    mounted() {
        axios.get("https://collectiflescouleursdelavie.fr:3000/getMessage", {headers: {'Content-Type': 'application/json'}})
        .then(response => {
            let dataTesti = response.data;
            this.getDataTesti.allPosts = dataTesti;
        })
        .catch(() => {
        });
    }
}
</script>

<style>
.testimony-titre {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: center url("../../assets/1920x2880-personne-agee.jpg");
    background-size: cover;
    background-attachment: fixed;
    height: 550px;
}

.testimony-h1 {
    background-color: rgb(0, 0, 0, .3);
    border-bottom: 2px solid rgba(0, 0, 0, 0.4);
    color: white;
    width: 100%;
    text-align: center;
}

#container-testi-text {
    background-color: white;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    max-width: 1000px;
    position: relative;
    top: -50px;
    margin: auto;
}

.btn-testi-close {
    margin-bottom: 15px;    
}

.select-display-form {
    display: flex;
    width: 100%;
    margin: auto;
    justify-content: center;
}
.select-display-form button {
    margin: 10px;
}

.btn-ajust {
    background-color: rgba(23, 61, 11, 0.5);
    border: 0;
}

.form-testi-ajust {
    position: absolute;
    top: -528px;
    right: -20px;
    margin: 0;
    background-color: rgb(255, 255, 255);
    width: 100%;
    z-index: 2;
}

.subtitle-testi {
    font-size: 2.5em;
    font-weight: normal;
    background-color: rgba(23, 61, 11, 0.5);
    color: white;
    box-shadow: 0px 0px 25px 1px rgba(128, 128, 128, 0.5);
    text-align: center;
    border-radius: 8px;
    padding-top: 20px;
    padding-bottom: 30px;
    margin-bottom: 30px;
    width: 100%;
}

.title-form-testi {
    margin-bottom: 10px;
    padding-left: 5px;
    padding-right: 5px;
    text-align: center;
}

#posts-testi {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 20px;
    margin-top: 40px;
    margin-bottom: 50px;
    padding: 10px;
    padding-top: 30px;
    padding-bottom: 30px;
    border-radius: 15px;
    box-shadow: 0px 0px 20px 1px rgb(233, 233, 233);
    animation: 2s formulaire;
}

/* Section posts */
#section-posts {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 5px;
    margin-bottom: 15px;
    padding: 7px;
    border-radius: 15px;
    box-shadow: 0px 0px 20px 1px rgb(233, 233, 233);
}

.posts-list {
    display: flex;
    flex-direction: column;
}

.posts-list-title {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-bottom: 20px;
    border-bottom: 2px double rgba(23, 61, 11, 0.5);
}

.posts-list-title h5 {
    margin-left: 6px;
    margin-right: 6px;
}

.txt {
    font-size: 1.1em;
    text-align: end;
}

@media screen and (max-width: 480px) {
    .testimony-titre {
        background-attachment: scroll;
    }
}
</style>